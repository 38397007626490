/* eslint react/prop-types: 0 */
import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ContactForm from '../components/contact-sanpham';
import AppAppBar from '../components/navbar/navbarvn';
import AppFooter from '../components/homepagevn/Footer';

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(10),
    },
  },
  contentContainer: {
    borderRight: '1px solid #ddd',
    [theme.breakpoints.up('md')]: {
      paddingRight: '3rem !important',
      textAlign: 'justify',
    },
  },
  header: {
    fontSize: '2rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      marginTop: '8rem',
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '2rem',
    },
    lineHeight: '1.5',
  },
  content: {
    fontSize: '1.14rem',
    lineHeight: '1.5',
  },
  nextPost: {
    fontSize: '1.14rem',
    color: '#337ab7',
    textDecoration: 'none',
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
  },
  previousPost: {
    fontSize: '1.14rem',
    color: '#337ab7',
    textDecoration: 'none',
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
  },
  previousTitle: {
    color: '#b4b5bb',
    fontSize: '1.14rem',
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
  },
  nextTitle: {
    color: '#b4b5bb',
    fontSize: '1.14rem',
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
  },
  nextpreviousPost: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  leftButton: {
    alignItems: 'left',
  },
  cardPost: {
    width: '50%',
  },
  relatedProducts: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
    marginBottom: theme.spacing(12),
  },
  relatedBlogTittle: {
    fontSize: '1.71rem',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  contactTittle: {
    marginTop: theme.spacing(8),
    fontWeight: 700,
    fontSize: '1.14rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  buttonContact: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  contactButton: {
    fontSize: '1.14rem',
    borderRadius: 3,
    height: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '12px',
    paddingBottom: '12px',
    backgroundColor: '#f2ae1c',
    color: '#fff',
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
  },
  image: {
    maxWidth: '80%',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  contentSubTitle: {
    fontSize: '1rem',
    color: '#615b5b',
    borderBottom: '1px solid #ddd',
    paddingBottom: '1rem',
    textTransform: 'uppercase',
  },
  headerContainer: {
    marginBottom: '3rem',
  },
}));

export default function BlogPost({ data }) {
  const classes = useStyles();
  const post = data.mdx;
  return (
    <>
      <AppAppBar />
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={post.frontmatter.description} />
        <meta name="keywords" content={post.frontmatter.keyword} />
        <title>{post.frontmatter.title_meta}</title>
      </Helmet>
      <Container className={classes.cardGrid}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.headerContainer}>
            <Typography variant="h4" marked="center" align="center" component="h1" className={classes.header}>
              {post.frontmatter.title}
            </Typography>
            <Typography align="center" className={classes.contentSubTitle}>
              HOME - BẢN TIN -
              {post.frontmatter.category}
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} lg={8} className={classes.contentContainer}>
            <div className="blogbody">
              <MDXRenderer>{post.body}</MDXRenderer>
            </div>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <ContactForm />
          </Grid>
        </Grid>
      </Container>
      <AppFooter />
    </>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        category
        slug
        title
        title_meta
        description
        keyword
      }
    }
  }
`;
